<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">


        <router-link class="goBack" to="/task2">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </router-link>

        <h1>
          <span>Задача: оценка компетенций заявителя</span>          
        </h1>

        <p>Бердашкевич Максим Сергеевич</p>

        <p>Укажите уровень компетенций гражданина (фактический)</p>


        <table class="table">
          <thead>
            <tr>
              <th>Компетенции гражданина</th>
              <th>Выберите уровень развития</th>
              <th>Укажите источник</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Уверенный пользователь ПК</td>    
              <td>
                <v-select dense style="width: 70px"></v-select>
              </td>          
              <td>
                <v-select dense style="width: 170px"></v-select>
              </td>          
            </tr>    
            <tr>
              <td>Название компетенции</td>    
              <td>
                <v-select dense style="width: 70px"></v-select>
              </td>          
              <td>
                <v-select dense style="width: 170px"></v-select>
              </td>          
            </tr>   
            <tr>
              <td>Название компетенции</td>    
              <td>
                <v-select dense style="width: 70px"></v-select>
              </td>          
              <td>
                <v-select dense style="width: 170px"></v-select>
              </td>          
            </tr>            
          </tbody>
        </table>

        <router-link to="/task4"><v-btn color="blue" style="width: 360px">ДАЛЕЕ</v-btn></router-link>
         



      </div>
    </transition>
  </div>
</template>

<script>


export default {
  name: 'Directories',

  data() {
    return {

    };
  
	}
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
